// Polyfill imports have to be on first line
import "react-app-polyfill/stable";
import React from "react";
// import { ComponentName } from "careibu-constants/component/ComponentName.js";
import { createRoot } from "react-dom/client";
import {
  AppName,
} from "./utils/appHostnames";

function importAppRoot() {
  return importAppByName(import.meta.env.REACT_APP_COMPONENT_NAME);
}

const importAppByName = (name: AppName | null) => {
  console.log("Log app name: \t %s", name);
  switch(name) {
    case "student":
      return import("./app/student/App");
    case "client":
      return import("./app/client/App");
    case "senior":
      return import("./app/senior/App");
    default:
      throw new Error(
        "Unknown App"
      );
  }
}


importAppRoot().then(({ App }) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<App />);
});
